import { produce } from "immer";

const savedFilterCrop = localStorage.getItem("agricircle_crop");

const initialState = {
    crops: null,
    filterCrop: (savedFilterCrop && savedFilterCrop !== "null") || null,
    mode: "work"
};

const reducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case "SET_CROPS":
            const cropsMap = {};
            if (action.payload.crops?.length)
                action.payload.crops.forEach(c => cropsMap[c.id] = c);
            draft.crops = { list: action.payload.crops, map: cropsMap };
            if (action.payload.crops && state.filterCrop) {
                if (!state.crops.find(c => c.id === state.filterCrop))
                    draft.filterCrop = null;
            }
            break;
        case "SET_FILTER_CROP":
            draft.filterCrop = action.payload.filterCrop;
            break;
        case "SET_MODE":
            draft.mode = action.payload.mode;
            break;
    }
});

export default reducer;

export const setCrops = (crops) => ({ type: "SET_CROPS", payload: { crops } });

export const setFilterCrop = (filterCrop) => ({ type: "SET_FILTER_CROP", payload: { filterCrop } });

export const setMode = (mode) => ({ type: "SET_MODE", payload: { mode } });

