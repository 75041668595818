import { useState } from "react";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from '@mui/icons-material/Undo';

export const SelfEditTextField = ({ value, onSave, ...props }) => {
    const [editedValue, setEditedValue] = useState(null);
    const [modified, setModified] = useState();

    function handleToggleEdit() {
        setEditedValue(editedValue == null ? value : null);
        setModified(false);
    };

    function handleChange(e) {
        setEditedValue(e.target.value);
        setModified(e.target.value !== value);
    }

    function handleSave() {
        if (editedValue != value && onSave) {
            onSave(editedValue);
            setEditedValue(null);
        }
    };

    return (<TextField
        {...props}
        value={editedValue == null ? value : editedValue}
        onChange={handleChange}
        onFocus={handleToggleEdit}
        onBlur={() => setTimeout(handleToggleEdit, 150)}
        slotProps={editedValue && {
            input: {
                endAdornment: modified && <InputAdornment position="end">
                    <div>
                        <IconButton size="small" onClick={handleToggleEdit} edge="end"><UndoIcon /></IconButton>
                        <IconButton size="small" onClick={handleSave} edge="end"><SaveIcon /></IconButton>
                    </div>
                </InputAdornment>
            }
        }}
    />);
};
