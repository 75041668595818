import { Stack, Button, Box, Typography } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "@agricircle/shared";
import { apiUrl } from "@agricircle/shared/hooks";
import { LanguageSelector } from "@agricircle/shared/widgets";
import "./Home.css";
import logo from "@agricircle/shared/assets/agricircle_logo.svg";
import tornPaper from "../assets/torn-paper.webp";

const YOUTUBE_VIDEOS = [
    {
        en: "lBGbU_l3g6w",
        de: "lYUzTBGZ8Fg"
    },
    {
        en: "FQJLpCkurZ8",
        de: "lsGXgvuIYLI"
    },
    {
        en: "xd_yye6zy6Q",
        de: "WyFD4JqZS-A"
    }
];

const Promotion = ({ title, text }) => (<Box sx={{ position: "relative", marginLeft: "auto", marginRight: "auto" }}>
    <img src={tornPaper} width="380" height="300" />
    <Box sx={{ position: "absolute", top: 80, left: "50%", transform: "translate(-50%, 0)", color: "#5603ad" }}>
        <Box sx={{ width: "100%", textAlign: "center", fontSize: "23px", fontWeight: "bold", marginBottom: "5px" }}>{title}</Box>
        <Box sx={{ width: "100%", textAlign: "center", fontSize: "14px" }}>{text}</Box>
    </Box>
</Box>);


export default () => {
    const { t, i18n } = useTranslation();
    const { profile } = useAuthContext();
    const lang = i18n.language == "de" ? "de" : "en";
    const unauthorized = profile.id && !profile.organization?.roles?.includes("myfarmiq");

    function handleLogin() {
        window.location.href = `${apiUrl}/auth/login?after_login=https://${window.location.host}/map`;
    }

    function handleRegister() {
        window.location.href = `${apiUrl}/auth/login?register=true&after_login=https://${window.location.host}/map`;
    }

    function handleVideo(video) {
        window.open(`https://www.youtube.com/embed/${video[lang]}?autoplay=1`, "_blank");
    }

    return (<Box className="home" sx={{ width: "100%", backgroundImage: "url(/farmiq.webp)", backgroundSize: "cover" }}>
        <Box sx={{ backdropFilter: "brightness(60%) blur(3px)", padding: "10px" }}>
            <Stack sx={{ maxWidth: 1050, marginLeft: "auto", marginRight: "auto" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                        <img src={logo} style={{ width: 60 }} />
                        <LanguageSelector small sx={{ display: "block", fontSize: "80%" }} />
                    </Stack>
                    <Stack alignItems="end">
                        <h1>{t("title-farmiq")}</h1>
                        <Stack direction="row" alignItems="center">
                            {profile.id
                                ? unauthorized
                                    ? <Typography color="error">{t("unauthorized")}</Typography>
                                    : <Button
                                        href="/map"
                                        variant="outlined"
                                        style={{ color: "white", borderColor: "white" }}
                                    >{t("btn-my-account")}
                                    </Button>
                                : <Stack direction="row" spacing={2}>
                                    <Button
                                        color="success"
                                        variant="outlined"
                                        onClick={handleLogin}
                                        style={{ color: "white", borderColor: "white" }}
                                        data-cy="nav-login"
                                    >{t("btn-login")}
                                    </Button>
                                </Stack>}
                        </Stack>
                    </Stack>
                </Stack>
                <h2>{t("title-farmiq-tagline")}</h2>
                {(!profile.id || null) && <Promotion title={t("title-farmiq-promotion")} text={t("text-farmiq-promotion")} />}
                {(!profile.id || null) && (<Button
                    color="success"
                    variant="contained"
                    onClick={handleRegister}
                    sx={{ width: 200, marginLeft: "auto", marginRight: "auto", marginTop: "-50px", marginBottom: "30px" }}
                >
                    {t("btn-register")}
                </Button>)}
                <div className="tutorial">
                    {YOUTUBE_VIDEOS.map(video => (<a onClick={() => handleVideo(video)} key={video.en}>
                        <img src={`https://img.youtube.com/vi/${video[lang]}/mqdefault.jpg`} />
                        <PlayCircleFilledWhiteIcon color="red" sx={{
                            width: "50px",
                            height: "50px",
                            opacity: 0.7,
                            color: "gray",
                            position: "absolute",
                            top: "55px",
                            right: "22px",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1
                        }} />
                    </a>))}
                </div>
                <h3>{t("title-farmiq-how-it-works")}</h3>
                <div dangerouslySetInnerHTML={{ __html: t("text-farmiq-how-it-works") }} />
                <h3>{t("title-farmiq-value")}</h3>
                <div dangerouslySetInnerHTML={{ __html: t("text-farmiq-value") }} />
                <h3>{t("title-farmiq-next")}</h3>
                <div dangerouslySetInnerHTML={{ __html: t("text-farmiq-next") }} />
            </Stack>
        </Box>
    </Box>);
};
