import { produce } from "immer";

const savedSelectedFarmId = localStorage.getItem("agricircle_farm");
const savedMapLayer = localStorage.getItem("agricircle_map_layer");

const initialState = {
    farms: null,
    selectedFarm: (savedSelectedFarmId && savedSelectedFarmId !== "null") ? { id: savedSelectedFarmId } : null,
    farmImport: null,
    mapPosition: null,
    mapLayer: (savedMapLayer && savedMapLayer != "null") ? savedMapLayer : null
};

export const farmsReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case "SET_FARMS": {
            const farms = action.payload.farms;
            if (farms) {
                if (farms.length) {
                    const selectedFarmId = farms.find(farm => farm.id === state.selectedFarm?.id)?.id || null;
                    if (!selectedFarmId) {
                        localStorage.setItem("agricircle_farm", farms[0].id);
                        draft.importingFarm = null;
                    }
                    draft.selectedFarm = { id: selectedFarmId };
                } else {
                    draft.selectedFarm = null;
                    localStorage.setItem("agricircle_farm", null);
                    draft.importingFarm = null;
                }
                draft.farms = farms;
            } else {
                draft.farms = null;
                draft.selectedFarm = state.selectedFarm ? { id: state.selectedFarm.id } : null;
            }
            break;
        }
        case "SET_SELECTED_FARM": {
            const selectedFarm = state.farms ? state.farms.find(farm => farm.id === action.payload.farmId) || null : { id: action.payload.farmId };
            draft.selectedFarm = selectedFarm ? { ...selectedFarm, fields: action.payload.fields } : null;
            localStorage.setItem("agricircle_farm", action.payload.farmId);
            break;
        }
        case "ADD_FIELD":
            if (state.selectedFarm?.fields) {
                const newFields = [...state.selectedFarm.fields, action.payload.field];
                newFields.sort((f1, f2) => ((f1.name < f2.name) ? -1 : ((f1.name > f2.name) ? 1 : 0)));
                draft.selectedFarm = { ...state.selectedFarm, fields: newFields, ts: Date.now() };
            }
            break;
        case "UPDATE_FIELD":
            if (state.selectedFarm?.fields) {
                const updatedFields = state.selectedFarm.fields.map(field => (field.id === action.payload.field.id) ? action.payload.field : field);
                updateFields.sort((f1, f2) => ((f1.name < f2.name) ? -1 : ((f1.name > f2.name) ? 1 : 0)));
                draft.selectedFarm = { ...state.selectedFarm, fields: updatedFields, ts: Date.now() };
            }
            break;
        case "DELETE_FIELD": {
            if (state.selectedFarm?.fields) {
                const remainingFields = state.selectedFarm.fields.filter(field => field.id !== action.payload.fieldId);
                draft.selectedFarm = { ...state.selectedFarm, fields: remainingFields, ts: Date.now() };
            }
            break;
        }
        case "SET_FARM_IMPORT":
            draft.farmImport = action.payload.farmImport;
            break;
        case "UPDATE_IMPORT_FIELD": {
            if (state.farmImport?.fields?.length) {
                const updateFields = state.farmImport.fields.map(field => (field.id === action.payload.field.id) ? action.payload.field : field);
                draft.farmImport = { ...state.farmImport, fields: updateFields };
            }
            break;
        }
        case "DELETE_IMPORT_FIELD": {
            if (state.farmImport?.fields?.length) {
                const remainingFields = state.farmImport.fields.filter(field => field.id !== action.payload.fieldId);
                draft.farmImport = { ...state.farmImport, fields: remainingFields };
            }
            break;
        }
        case "SET_MAP_POSITION":
            draft.mapPosition = action.payload.position;
            break;
        case "SET_MAP_LAYER":
            draft.mapLayer = action.payload.layer;
            localStorage.setItem("agricircle_map_layer", action.payload.layer)
            break;
    }
    return draft;
});

export const reloadFarms = () => ({ type: "SET_FARMS", payload: { farms: null } });

export const setFarms = (farms) => ({ type: "SET_FARMS", payload: { farms } });

export const setSelectedFarm = (farmId, fields) => ({ type: "SET_SELECTED_FARM", payload: { farmId, fields } });

export const addField = (field) => ({ type: "ADD_FIELD", payload: { field } });

export const updateField = (field) => ({ type: "UPDATE_FIELD", payload: { field } });

export const deleteField = (fieldId) => ({ type: "DELETE_FIELD", payload: { fieldId } });

export const setFarmImport = (farmImport) => ({ type: "SET_FARM_IMPORT", payload: { farmImport } });

export const updateImportField = (field) => ({ type: "UPDATE_IMPORT_FIELD", payload: { field } });

export const deleteImportField = (fieldId) => ({ type: "DELETE_IMPORT_FIELD", payload: { fieldId } });

export const setMapPosition = (position) => ({
    type: "SET_MAP_POSITION",
    payload: {
        position: position && {
            zoom: position.zoom,
            center: { lat: position.center.lat, lng: position.center.lng }
        }
    }
});

export const setMapLayer = (layer) => ({ type: "SET_MAP_LAYER", payload: { layer } });
